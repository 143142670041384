import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/client';
import { Actions, Button, Container, Divider, FlexGrid, Header, Image, Modal, Picture, Text } from '@gasbuddy/react-components';
import { CreditAppComplete, CREDIT_APPLICATION_STATUS } from '@gasbuddy/react-consumer-components';
import { useWindowSize } from '@gasbuddy/react-hooks/build/hooks/useWindowSize';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useParams } from 'react-router';
import useAnalytics from '../../../lib/hooks/useAnalytics';
import useConfig from '../../../lib/hooks/useConfig';
import useImageSrcBuilder from '../../../lib/hooks/useImageSrcBuilder';
import useMastercardPlacements, { ADS_LOCATION } from '../../../lib/hooks/useMastercardPlacements';
import useProfile from '../../../lib/hooks/useProfile';
import { interpolate, noop } from '../../../lib/utils';
import generateCMSMetaTags from '../../../lib/utils/generateCMSMetaTags';
import { LandingPagePropType } from '../../prop-types/cms-page';
import Login from '../Login';
import Markdown from '../Markdown';
import Markup from '../Markup';
import Page from '../Page';
import DefaultCallToAction from './DefaultCallToAction';
import Hero from './Hero';
import styles from './LandingPageTemplate1.module.css';

const cx = classnames.bind(styles);

const PAGE_NAME = 'Pay_Landing';
const buildLandingPagePlacement = domID => ({
  domID,
  locationType: ADS_LOCATION.Landing,
  placementId: '6c26a20f-b7ba-4112-8e0e-7277d0a86941',
});
const MASTERCARD_PLACEMENTS = ['heroCTA', 'howToSaveCTA', 'benefitsCTA', 'bottomHeroCTA'].map(buildLandingPagePlacement);

function formatAlt(alt) {
  return `Pay with GasBuddy - Apply for Gas Card - ${alt}`;
}

export const GET_CC_STATUS = gql`
  query GetCCFeature {
    feature(name: "enroll_cc") {
      n
      p
    }
  }
`;

function MastercardCallToAction({ href, isLoggedIn, onClick, ...rest }) {
  const handleClick = useCallback((e) => {
    if (isLoggedIn) {
      // If not logged in, let browser navigate to href (login)
      e.preventDefault();
    }

    if (onClick) {
      onClick(e);
    }
  }, [isLoggedIn, onClick]);

  const { data } = useQuery(GET_CC_STATUS);

  const creditEnrollment = data?.feature.p;
  const canApply = creditEnrollment?.canApply;

  if (!canApply) {
    const reason = creditEnrollment?.reason || 'This service is undergoing maintenance. We apologize for any inconvenience.';
    const continueText = creditEnrollment?.continueText || 'Okay';
    const continueUrl = creditEnrollment?.continueUrl;
    const continueInNewTab = !!creditEnrollment?.continueNewTab;
    const ctaProps = continueUrl ? {
      as: 'a',
      href: continueUrl,
      onClick: noop,
      target: continueInNewTab ? '_blank' : undefined,
    } : {};

    return (
      <Modal
        content={({ close }) => (
          <Fragment>
            <Text as="p">{reason}</Text>
            <Actions>
              <Actions.Button
                primary
                wide
                onClick={close}
                desktop={6}
                {...ctaProps}
              >
                {continueText}
              </Actions.Button>
            </Actions>
          </Fragment>
        )}
        size="sm"
      >
        {({ open }) => (
          <DefaultCallToAction
            onClick={(e) => { e.preventDefault(); open(); }}
            {...rest}
            href={isLoggedIn ? href : `/login?return_url=${href}`}
          />
        )}
      </Modal>
    );
  }

  return (
    <DefaultCallToAction
      onClick={handleClick}
      {...rest}
      href={isLoggedIn ? href : `/login?return_url=${href}`}
    />
  );
}

MastercardCallToAction.propTypes = {
  href: PropTypes.string,
  isLoggedIn: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
};

MastercardCallToAction.defaultProps = {
  href: undefined,
  onClick: undefined,
};

function QuestionListItem({ children, question }) {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggleClick = useCallback(() => {
    setIsOpen(isCurrentlyOpen => !isCurrentlyOpen);
  }, []);

  // See below link for reasoning behind button element
  // https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/no-noninteractive-element-interactions.md#case-this-is-a-heading-that-expandscollapses-content-on-the-package
  return (
    <div className={cx('item', { active: isOpen })} itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
      <button className={cx('reset')} onClick={handleToggleClick} type="button">
        <span className={cx('chevron', isOpen ? 'up' : 'down')} />
        <strong className={cx('question')} itemProp="name"><Markup source={interpolate(question)} /></strong>
      </button>
      <div className={cx('answer')} itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
        <div itemProp="text">{children}</div>
      </div>
    </div>
  );
}

QuestionListItem.propTypes = {
  children: PropTypes.node,
  question: PropTypes.string,
};

QuestionListItem.defaultProps = {
  children: undefined,
  question: undefined,
};

function Quote({ children, image, imageAlt, name }) {
  return (
    <FlexGrid className={cx('quoteRow')} container>
      <FlexGrid.Column tablet={4}>
        <Image
          src={image}
          alt={imageAlt || formatAlt(name)}
        />
      </FlexGrid.Column>
      <FlexGrid.Column className={cx('quoteCol')} tablet={8}>
        <div className={cx('quote')}>
          <Image
            src="https://static.gasbuddy.com/web/pay/landing/Quote.svg"
            alt={formatAlt('Quote Mark')}
          />
          <blockquote>
            {children}
          </blockquote>
          <strong>{name}</strong>
        </div>
      </FlexGrid.Column>
    </FlexGrid>
  );
}

Quote.propTypes = {
  children: PropTypes.node.isRequired,
  image: PropTypes.string.isRequired,
  imageAlt: PropTypes.string,
  name: PropTypes.string.isRequired,
};

Quote.defaultProps = {
  imageAlt: undefined,
};

const TAG_ACCOUNT = gql`
  mutation($tagType: String!, $value: String!) {
    tagAccount(tagType: $tagType, value: $value) {
      success
    }
  }
`;


function HowToSave({
  cta: CallToAction,
  ctaDisclaimer,
  ctaLabel,
  description,
  href,
  imageAlt,
  imgSrc,
  includeDivider,
  isLoggedIn,
  onClick,
  title,
}) {
  return (
    <section className={cx('howToPay')}>
      <Container>
        <FlexGrid className={cx('textAndImageWrapper')} container>
          <FlexGrid.Column className={cx('textWrapperCol')} tablet={7}>
            <div className={cx('textWrapper')}>
              <Header as="h2"><Markup source={interpolate(title)} /></Header>
              <p>
                <Markup source={interpolate(description)} />
              </p>
              <CallToAction
                href={href}
                id="howToSaveCTA"
                isLoggedIn={isLoggedIn}
                onClick={onClick}
              >
                {ctaLabel}
              </CallToAction>
              {!!ctaDisclaimer && (
                <Text className={cx('ctaDisclaimer')} size="sm">{ctaDisclaimer}</Text>
              )}
            </div>
          </FlexGrid.Column>
          <FlexGrid.Column className={cx('imgWrapper')} tablet={5}>
            <Image
              src={imgSrc}
              alt={imageAlt}
            />
          </FlexGrid.Column>
        </FlexGrid>
        {includeDivider && (
          <hr />
        )}
      </Container>
    </section>
  );
}

HowToSave.propTypes = {
  cta: PropTypes.elementType,
  ctaDisclaimer: PropTypes.string,
  ctaLabel: PropTypes.string,
  description: PropTypes.string,
  href: PropTypes.string,
  imageAlt: PropTypes.string,
  imgSrc: PropTypes.string,
  includeDivider: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  onClick: PropTypes.func,
  title: PropTypes.string,
};

HowToSave.defaultProps = {
  cta: DefaultCallToAction,
  ctaDisclaimer: undefined,
  ctaLabel: undefined,
  description: undefined,
  href: undefined,
  imageAlt: undefined,
  imgSrc: undefined,
  includeDivider: false,
  isLoggedIn: undefined,
  onClick: noop,
  title: undefined,
};
export function TermsAndConditions({ title, terms }) {
  return (
    <div className={cx('termsAndConditions')}>
      {title && (
        <div className={cx('title')}>
          <Markup source={interpolate(title)} />
        </div>
      )}
      <div className={cx('legalese')}>
        <Markup source={interpolate(terms)} />
      </div>
    </div>
  );
}

TermsAndConditions.propTypes = {
  title: PropTypes.string,
  terms: PropTypes.string.isRequired,
};

TermsAndConditions.defaultProps = {
  title: undefined,
};

const shouldShowCreditResult = result => [
  CREDIT_APPLICATION_STATUS.Approved,
  CREDIT_APPLICATION_STATUS.Pending,
  CREDIT_APPLICATION_STATUS.Declined,
].includes(result);

export default function LandingPageTemplate1({ page }) {
  const { isLoggedIn } = useProfile();
  const newSection = name => page?.sectionList?.find(section => section.name === name);
  const oldSection = name => page?.sections?.find(section => section.name === name);
  const faqs = page?.faqs?.questions || page?.faqs || []; // new {faqs: {questions: [], title: ''}} vs old {faqs: []}
  const faqSet1 = faqs?.slice(0, Math.ceil(faqs.length / 2));
  const faqSet2 = faqs?.slice(faqSet1.length);
  const requiresMastercardSDK = !!page?.adsSdk;
  const meta = page?.meta?.find(el => el.type === 'meta');
  const howItWorksSection = newSection('howItWorks');
  const howToSaveTopSection = newSection('howToSaveTop');
  const termsAndConditionsSection = newSection('termsAndConditions');
  const heroSection = oldSection('hero');
  const bottomHeroSection = oldSection('bottomHero');
  const howToSaveBottom = oldSection('howToSave');
  const phoneSection = oldSection('phone');
  const prImages = page?.imageSets?.find(imageSet => imageSet.name === 'PR Images')?.images;
  const metricImages = page?.imageSets?.find(imageSet => imageSet.name === 'Value Prop Images')?.images;
  const phoneImages = page?.imageSets?.find(imageSet => imageSet.name === 'Phone Value Prop Images')?.images;
  const markupImages = page?.imageSets?.find(imageSet => imageSet.name === 'Structured Markup Image Objects')?.images;
  const phoneImageSet1 = phoneImages?.slice(0, Math.ceil(phoneImages.length / 2));
  const phoneImageSet2 = phoneImages?.slice(phoneImageSet1.length);
  const cardComparison = newSection('comparison');
  const fullscreen = page?.sections?.length + page?.sectionList?.length === 1;

  const location = useLocation();
  const { consumerHost } = useConfig();
  const imageToSrc = useImageSrcBuilder();
  const attachQueryString = baseUrl => `${baseUrl}${location.search}`;

  const { loyaltyID } = useParams();

  const creditAppStatus = useMastercardPlacements(MASTERCARD_PLACEMENTS, isLoggedIn && requiresMastercardSDK, loyaltyID);
  const showCreditResult = creditAppStatus && shouldShowCreditResult(creditAppStatus);

  const primaryImageObjDetails = markupImages && {
    contentUrl: imageToSrc(markupImages[0].image),
    description: markupImages[0].alt,
    name: imageToSrc(markupImages[0].image).slice(0, -4),
  };

  const CallToAction = useMemo(() => ((requiresMastercardSDK) ? MastercardCallToAction : DefaultCallToAction), [requiresMastercardSDK]);

  const accountTagEvents = page?.actions?.filter(action => action.type === 'tag_account') || [];
  const PageViewTag = accountTagEvents?.find(event => event.trigger === 'view');
  const localyticsEvents = page?.actions?.filter(action => action.type === 'send_localytics') || [];
  const PageViewEvent = localyticsEvents?.find(event => event.trigger === 'view');
  const CTAClickEvent = localyticsEvents?.find(event => event.trigger === 'click');

  const [tagAccount] = useMutation(TAG_ACCOUNT);
  const analytics = useAnalytics();

  const createCTAClickHandler = useCallback(section => () => {
    if (CTAClickEvent) {
      const attributes = section ? { section_name: section } : {};

      if (CTAClickEvent.eventAttributes) {
        CTAClickEvent.eventAttributes.forEach((attr) => {
          attributes[attr.name] = attr.value;
        });
      }

      analytics.tagEvent({ name: CTAClickEvent.name, attributes });
    }
  }, [CTAClickEvent, analytics]);

  useEffect(() => {
    if (PageViewTag) {
      tagAccount({
        variables: {
          tagType: PageViewTag.tagType,
          value: PageViewTag.value,
        },
      });
    }
  }, [PageViewTag, tagAccount]);

  useEffect(() => {
    if (PageViewEvent) {
      analytics.tagPageView({ name: PageViewEvent.name, eventPostfix: '' }); // Reset postfix to '' since _Opened is part of event already
    }
  }, [PageViewEvent, analytics]);

  const productSchema = page?.product && { product: page.product, helpers: { imageToSrc } };
  const price = page?.product?.offers?.[0]?.price?.amount;
  const { mobile: isMobileWindow } = useWindowSize();

  const { image: partnerLogo, alt: partnerLogoAlt } = isMobileWindow && page?.partnerMobileLogo
    ? page?.partnerMobileLogo
    : (page?.partnerLogo || {});

  const isBatchPrescreenPage = location.pathname.endsWith('/accept');

  if (!isLoggedIn && isBatchPrescreenPage) {
    return (
      <Login />
    );
  }

  return (
    <Page
      activeNavKey="pay_with_gasbuddy"
      className={cx('payLanding')}
      name={PAGE_NAME}
      compact
      primaryImageObjDetails={primaryImageObjDetails}
      title={page?.title}
      productSchema={productSchema}
      description={meta?.content}
      isPartner={!!partnerLogo}
      withFooter={!partnerLogo}
    >
      <Helmet>
        {page?.title && (
          <title>{page.title}</title>
        )}
        {page?.canonicalUrl && (
          <link rel="canonical" href={attachQueryString(page.canonicalUrl)} />
        )}

        {!!page?.meta?.length && generateCMSMetaTags(page.meta, imageToSrc)}
        {requiresMastercardSDK && (
          <script type="text/javascript">
            {`
            if (window.BreadPayments = window.BreadPayments || { snippetVersion: "1.2.1" }, !BreadPayments.init) { var b = BreadPayments; b.on = function () { var t = ["on"].concat(Array.prototype.slice.call(arguments)); (b.q = b.q || []).push(t) }, b.submitRtps = function () { var t = ["submitRtps"].concat(Array.prototype.slice.call(arguments)); (b.q = b.q || []).push(t) }, b.setup = function () { var t = ["setup"].concat(Array.prototype.slice.call(arguments)); (b.q = b.q || []).push(t) }, b.registerPlacements = function () { var t = ["registerPlacements"].concat(Array.prototype.slice.call(arguments)); (b.q = b.q || []).push(t) } }
          `}
          </script>
        )}
        {requiresMastercardSDK && (
          <script
            data-bread-payments-key="d85bb52f-bb3d-431d-a7d7-a95804e689ef"
            async
            src="https://connect.breadpayments.com/unified.sdk.js"
          />
        )}
      </Helmet>

      {showCreditResult && (
        <CreditAppComplete
          consumerHost={consumerHost}
          status={creditAppStatus}
        />
      )}

      <Fragment>
        {partnerLogo && (
          <Container className={cx('header')}>
            <Image
              alt="GasBuddy"
              className={cx('brand')}
              src="https://static.gasbuddy.com/web/gas-buddy-logo-midnight-txt.svg"
            />
            <Divider flex thick vertical />
            <Image
              alt={partnerLogoAlt}
              className={cx('brand', 'partner')}
              src={imageToSrc(partnerLogo)}
            />
          </Container>
        )}

        {heroSection && (
          <Hero
            buttonHref={attachQueryString(heroSection?.ctaUrl)}
            cta={CallToAction}
            heroData={heroSection}
            isLoggedIn={isLoggedIn}
            offerPrice={price}
            onButtonClick={createCTAClickHandler(heroSection?.name)}
            className={cx({ fullscreen })}
          />
        )}

        {cardComparison && (
          <FlexGrid container>
            <FlexGrid.Column className={cx('comparisonContainer')}>
              <div className={`${cx('topComparison')} ${cx('comparison')}`}>
                <Header as="h2">
                  {cardComparison.title}
                </Header>
                <div className={cx('columnsContainer')}>
                  {cardComparison.blocks.map(comparison => (
                    <div className={cx('column')} key={comparison.title}>
                      <Header as="h4">
                        <Markup source={interpolate(comparison.title)} />
                      </Header>
                      <Image
                        src={imageToSrc(comparison.image)}
                        alt={comparison.imageAlt || formatAlt(comparison.title)}
                      />
                      <Text as="p" className={cx('columnDescription')}>
                        <Markup source={interpolate(comparison.description)} />
                      </Text>
                      <Button
                        as="a"
                        href={comparison?.ctaUrl}
                        className={cx('cta')}
                        onClick={createCTAClickHandler(comparison.name)}
                        primary
                        size="md"
                      >
                        <Markup source={interpolate(comparison?.ctaLabel)} />
                      </Button>
                      {comparison.ctaDisclaimer && (
                        <Text className={cx('ctaDisclaimer')} size="sm">{comparison.ctaDisclaimer}</Text>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </FlexGrid.Column>
          </FlexGrid>
        )}

        {(page?.saveHeadline || !!prImages?.length) && (
          <section className={cx('featured')}>
            <Container>
              {page?.saveHeadline && (
                <Header as="h2">
                  <Markup source={interpolate(page.saveHeadline)} />
                </Header>
              )}
              {!!prImages?.length && (
                <div className={cx('logoRow')}>
                  {!!prImages?.length && prImages.map(({ alt, image }) => (
                    <div className={cx('logoCol')} key={image.url}>
                      <Image
                        src={imageToSrc(image)}
                        alt={alt}
                      />
                    </div>
                  ))}
                </div>
              )}
              <hr />
            </Container>
          </section>
        )}

        {howItWorksSection && (
          <section className={cx('howItWorks')}>
            <Container>
              <Header as="h2"><Markup source={interpolate(howItWorksSection.title)} /></Header>
              <div className={cx('steps')}>
                {howItWorksSection.blocks.map(({ alt, description, image, title }) => (
                  <div className={cx('step')} key={alt}>
                    <div> {/** Need this to use flex layout with ::before elment */}
                      <Image
                        src={imageToSrc(image)}
                        alt={alt}
                      />
                      <Text bold color="red" fluid size="xl" uppercase><Markup source={interpolate(title)} /></Text>
                      <Text as="p"><Markup source={interpolate(description)} /></Text>
                    </div>
                  </div>
                ))}
              </div>
              <hr />
            </Container>
          </section>
        )}

        {howToSaveTopSection && (
          <HowToSave
            cta={CallToAction}
            ctaLabel={howToSaveTopSection.blocks[0].ctaLabel}
            ctaDisclaimer={howToSaveTopSection.blocks[0].ctaDisclaimer}
            description={howToSaveTopSection.blocks[0].description}
            href={attachQueryString(howToSaveTopSection.blocks[0].ctaUrl)}
            imageAlt={howToSaveTopSection.blocks[0].alt}
            imgSrc={imageToSrc(howToSaveTopSection.blocks[0].image)}
            includeDivider={!!page?.quotes?.[0]}
            isLoggedIn={isLoggedIn}
            onClick={createCTAClickHandler(howToSaveTopSection.name)}
            title={howToSaveTopSection.title}
          />
        )}

        {page?.quotes?.[0] && (
          <section className={cx('quoteSection')}>
            <Container>
              <Quote
                image={imageToSrc(page.quotes[0].quote.image)}
                imageAlt={page.quotes[0].quote.alt}
                name={page.quotes[0].quote.name}
              >
                <Markup source={interpolate(page.quotes[0].quote.quote)} />
              </Quote>
            </Container>
          </section>
        )}

        {phoneSection && (
          <section className={cx('blueBg')}>
            <Container>
              <Header as="h2"><Markup source={interpolate(phoneSection.title)} /></Header>
              <FlexGrid container>
                <FlexGrid.Column mobile={6} tablet={4}>
                  {!!phoneImageSet1?.length && (
                    <FlexGrid container>
                      {phoneImageSet1.map(({ alt, content, image }) => (
                        <FlexGrid.Column key={image.url}>
                          <Image
                            className={cx('icon')}
                            src={imageToSrc(image)}
                            alt={alt}
                          />
                          <p className={cx('description')}>
                            <Markup source={interpolate(content)} />
                          </p>
                        </FlexGrid.Column>
                      ))}
                    </FlexGrid>
                  )}
                </FlexGrid.Column>
                <FlexGrid.Column className={cx('phoneImgCol')} tablet={4}>
                  <Image
                    className={cx('phoneImg')}
                    src={imageToSrc(phoneSection.image)}
                    alt={phoneSection.imageAlt}
                  />
                </FlexGrid.Column>
                <FlexGrid.Column mobile={6} tablet={4}>
                  {!!phoneImageSet2?.length && (
                    <FlexGrid container>
                      {phoneImageSet2.map(({ alt, content, image }) => (
                        <FlexGrid.Column key={image.url}>
                          <Image
                            className={cx('icon')}
                            src={imageToSrc(image)}
                            alt={alt}
                          />
                          <p className={cx('description')}>
                            <Markup source={interpolate(content)} />
                          </p>
                        </FlexGrid.Column>
                      ))}
                    </FlexGrid>
                  )}
                </FlexGrid.Column>
                <FlexGrid.Column>
                  <CallToAction
                    href={attachQueryString(phoneSection.ctaUrl)}
                    id="benefitsCTA"
                    isLoggedIn={isLoggedIn}
                    onClick={createCTAClickHandler(phoneSection.name)}
                  >
                    {phoneSection.ctaLabel}
                  </CallToAction>
                  {phoneSection.ctaDisclaimer && (
                    <Text className={cx('ctaDisclaimer')} size="sm">{phoneSection.ctaDisclaimer}</Text>
                  )}
                </FlexGrid.Column>
              </FlexGrid>
            </Container>
          </section>
        )}

        {page?.quotes?.[1] && (
          <section className={cx('quoteSection')}>
            <Container>
              <Quote
                image={imageToSrc(page.quotes[1].quote.image)}
                imageAlt={page.quotes[1].quote.alt}
                name={page.quotes[1].quote.name}
              >
                <Markup source={interpolate(page.quotes[1].quote.quote)} />
              </Quote>
              <hr />
            </Container>
          </section>
        )}

        {howToSaveBottom && (
          <HowToSave
            cta={CallToAction}
            ctaDisclaimer={howToSaveBottom.ctaDisclaimer}
            ctaLabel={howToSaveBottom.ctaLabel}
            description={howToSaveBottom.description}
            href={attachQueryString(howToSaveBottom.ctaUrl)}
            imageAlt={howToSaveBottom.imageAlt}
            imgSrc={imageToSrc(howToSaveBottom.image)}
            isLoggedIn={isLoggedIn}
            onClick={createCTAClickHandler(howToSaveBottom.name)}
            title={howToSaveBottom.title}
          />
        )}

        {page?.quotes?.[2] && (
          <section className={cx('quoteSection')}>
            <Container>
              <Quote
                image={imageToSrc(page.quotes[2].quote.image)}
                imageAlt={page.quotes[2].quote.alt}
                name={page.quotes[2].quote.name}
              >
                <Markup source={interpolate(page.quotes[2].quote.quote)} />
              </Quote>
              <hr />
            </Container>
          </section>
        )}

        {!!metricImages?.length && (
          <section className={cx('stats')}>
            <Container>
              <div className={cx('statsRow')}>
                <Header as="h2">
                  <Markup source={interpolate(page?.statsHeadline)} />
                </Header>

                <FlexGrid container>
                  {metricImages.map(({ alt, image, content }) => (
                    <FlexGrid.Column className={cx('statWrapper')} key={image.url} mobile={6} tablet={3}>
                      <Image
                        src={imageToSrc(image)}
                        alt={alt}
                      />
                      <div className={cx('textWrapper')}>
                        <Markup source={interpolate(content)} />
                      </div>
                    </FlexGrid.Column>
                  ))}
                </FlexGrid>
              </div>
              <hr />
            </Container>
          </section>
        )}

        {!!faqs?.length && (
          <section className={cx('faq')}>
            <Container>
              <Header as="h2">
                <Markup source={interpolate(page?.faqs?.title) || 'Pay with GasBuddy FAQ'} escapeHtml={false} />
              </Header>

              <FlexGrid className={cx('accordionRow')} container itemScope itemType="https://schema.org/FAQPage">
                <FlexGrid.Column className={cx('accordionCol')} tablet={6}>
                  {faqSet1.map(({ faq }) => (
                    <QuestionListItem key={faq.title} question={faq.question}>
                      <Markdown>{interpolate(faq.answer)}</Markdown>
                    </QuestionListItem>
                  ))}
                </FlexGrid.Column>

                <FlexGrid.Column className={cx('accordionCol')} tablet={6}>
                  {faqSet2.map(({ faq }) => (
                    <QuestionListItem key={faq.title} question={faq.question}>
                      <Markdown>{interpolate(faq.answer)}</Markdown>
                    </QuestionListItem>
                  ))}
                </FlexGrid.Column>
                {termsAndConditionsSection && (
                  <FlexGrid.Column>
                    <TermsAndConditions title={termsAndConditionsSection.title} terms={termsAndConditionsSection.blocks[0].description} />
                  </FlexGrid.Column>
                )}
              </FlexGrid>

            </Container>
          </section>
        )}

        {bottomHeroSection && (
          <section className={cx('dontMissOut')}>
            <Container className={cx('container')}>
              <div className={cx('textBoxWrapper')}>
                <Header as="h2"><Markup source={interpolate(bottomHeroSection.title)} /></Header>
                <p>
                  <Markup source={interpolate(bottomHeroSection.description)} />
                </p>
                <CallToAction
                  href={attachQueryString(bottomHeroSection.ctaUrl)}
                  id="bottomHeroCTA"
                  isLoggedIn={isLoggedIn}
                  onClick={createCTAClickHandler(bottomHeroSection.name)}
                >
                  {bottomHeroSection.ctaLabel}
                </CallToAction>
                {bottomHeroSection.ctaDisclaimer && (
                  <Text className={cx('ctaDisclaimer')} size="sm">{bottomHeroSection.ctaDisclaimer}</Text>
                )}
              </div>
            </Container>
            <Picture
              alt={bottomHeroSection.imageAlt}
              src={imageToSrc(bottomHeroSection.image)}
            >
              <Picture.Source
                media="(max-width: 991px)"
                srcSet={imageToSrc(bottomHeroSection.mobileImage)}
              />
            </Picture>
          </section>
        )}
      </Fragment>
    </Page>
  );
}

LandingPageTemplate1.propTypes = {
  page: LandingPagePropType,
};

LandingPageTemplate1.defaultProps = {
  page: undefined,
};
