import { Redirect, useLocation } from 'react-router';
import { isWindowAvailable } from '../../../lib/utils';
import useConfig from '../../../lib/hooks/useConfig';

export default function Login() {
  const { consumerHost, identityHost } = useConfig();
  const { pathname, search } = useLocation();
  const url = `//${identityHost}/login?return_url=//${consumerHost}${pathname}${search}`;
  const urlParts = url.split('?');

  // If this is client-side, send the user there to avoid errors with Redirect
  if (isWindowAvailable()) {
    window.location.replace(url);
    return null;
  }

  return (
    <Redirect
      to={{
        pathname: urlParts[0],
        search: `?${urlParts[1]}`,
        state: { from: window.location },
      }}
    />
  );
}
