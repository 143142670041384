/* global BreadPayments */
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/client';
import { CREDIT_APPLICATION_STATUS } from '@gasbuddy/react-consumer-components';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import PageContext from '../../common/context/page';
import logEvent from '../utils/logEvent';

export const ADS_LOCATION = {
  Homepage: 'homepage',
  Landing: 'landing',
  Search: 'search',
  Product: 'product',
  Category: 'category',
  Banner: 'banner',
  Checkout: 'checkout',
  Cart: 'cart',
};


const buildPlacement = options => ({
  financingType: 'card',
  ...options,
});

export const GET_BREAD_DETAILS = gql`
  query {
    config {
      env
      isMobile
    }
    profile {
      accountId
      loyaltyId
    }
  }
`;

export const SAVE_MASTERCARD_RESULT = gql`
  mutation SaveMastercardResult($phone: String, $loyaltyId: String!, $result: String!) {
    saveMastercardResult(phone: $phone, loyaltyId: $loyaltyId, result: $result) {
      success
    }
  }
`;

const BreadEvents = [
  'CARD:RECEIVE_APPLICATION_RESULT',
  'INSTALLMENT:APPLICATION_DECISIONED',
  'INSTALLMENT:APPLICATION_CHECKOUT',
  'CARD:SUBMIT_APPLICATION',
  'CARD:SUBMIT_PRESCREEN_APPLICATION',
  'CARD:SHOW_OVERLAY',
  'CARD:CLOSE_OVERLAY',
  'CARD:VIEW_PAGE',
  'RECEIVE_APPLICATION_RESULT',
];

export default function useMastercardPlacements(placements = [], enabled = true, loyaltyID) {
  const canApply = useContext(PageContext)?.features?.find(f => f.n === 'enroll_cc')?.p?.canApply;
  const { data } = useQuery(GET_BREAD_DETAILS, {
    skip: !enabled,
  });
  const [saveMastercardResult] = useMutation(SAVE_MASTERCARD_RESULT);
  const location = useLocation();
  const [status, setStatus] = useState();
  const customerAcceptedOffer = location.pathname.endsWith('/accept');

  const handleResult = useCallback((loyaltyId, result, phone) => {
    saveMastercardResult({
      variables: {
        phone,
        loyaltyId,
        result,
      },
    });
    setStatus(result);
  }, [saveMastercardResult]);

  useEffect(() => {
    if (data && enabled && canApply) {
      const { config, profile } = data;
      const isProduction = config?.env === 'production';
      const isMobile = config?.isMobile;
      const loyaltyId = profile?.loyaltyId || loyaltyID;

      BreadPayments.setup({
        storeNumber: isMobile ? 9993 : 9991,
        loyaltyID: loyaltyId,
        env: isProduction ? 'PROD' : 'STAGE',
        existingCardHolder: false,
      });

      const listen = eventName => BreadPayments.on(eventName, response => logEvent(eventName, response));
      BreadEvents.forEach(listen);

      BreadPayments.on('CARD:OFFER_RESPONSE', (response) => {
        logEvent('CARD:OFFER_RESPONSE', { response });
        // Only save result if offer was dismissed/declined by user
        if (['NO', 'ABANDONED'].includes(response)) {
          handleResult(loyaltyId, CREDIT_APPLICATION_STATUS.Dismissed);
        } else if (response === 'NOT_ME') {
          handleResult(loyaltyId, CREDIT_APPLICATION_STATUS.NotMe);
        }
      });

      BreadPayments.on('CARD:RECEIVE_APPLICATION_RESULT', ({ mobilePhone, result }) => {
        handleResult(loyaltyId, result, mobilePhone);
      });

      if (placements.length) {
        BreadPayments.registerPlacements(placements.map(buildPlacement));
      }

      if (customerAcceptedOffer) {
        BreadPayments.submitRtps({
          financingType: 'card',
          customerAcceptedOffer: true,
        });
      }
    }
  }, [canApply, customerAcceptedOffer, data, enabled, handleResult, loyaltyID, placements]);

  return status;
}
